<template>
  <div class="shopping com-overflow-hidden" style="padding: 30px 0">
    <div
      class="content home-width com-margin-center"
      style="width: 1200px; margin: 0 auto"
    >
      <div class="hd com-flex" style="display: flex">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="width: 95%"
          active-text-color="#ff6000"
          :active-index="1"
          :default-active="indexNumber"
        >
          <el-menu-item index="1" style="font-size: 18px; font-weight: 700">
            新增
          </el-menu-item>
          <el-menu-item index="2" style="font-size: 18px; font-weight: 700">
            历史
          </el-menu-item>
        </el-menu>
      </div>
      <div v-if="indexNumber == 1" style="padding: 20px 40px">
        <div style="">
          <el-form class="form" ref="form" :model="form" label-width="auto">
            <!-- <el-form-item label="联系电话" prop="phone">
              <el-input
                placeholder="请输入联系电话"
                v-model="form.phone"
                style="width: 220px"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="图片" prop="imageUrl">
              <div class="demo-image__preview">
                <el-upload
                  multiple
                  class="upload-demo"
                  style="max-width: 800px"
                  name="fileList"
                  action="#"
                  :with-credentials="true"
                  :file-list="fileListCopy"
                  accept=".jpg,.jpeg,.png,.JPG,.JPEG,.mp4,.webp"
                  list-type="picture-card"
                  ref="upload"
                  :http-request="uploadFileList"
                  :auto-upload="false"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-progress="handleProgress"
                  :on-change="handleFileChange"
                  :before-upload="beforeUpload"
                >
                  <!-- <el-upload
                  multiple
                  :http-request="uploadFileList"
                  :auto-upload="false"
                  style="max-width: 800px"
                  class="upload-demo"
                  name="fileList"
                  ref="upload"
                  list-type="picture"
                  action="#"
                  :with-credentials="true"
                  :file-list="fileListCopy"
                > -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      :key="file.uid"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="imagedialogVisible">
                  <img width="100%" style="z-index: 999" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-form-item>
            <!-- <el-form-item label="共享区域" prop="landLine" style="width: 100%">
              <el-select v-model="form.value" multiple placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="备注" prop="content" style="width: 100%">
              <el-input
                v-model="form.remark"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10 }"
                placeholder="请输入内容"
                style="width: 100%"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="display: flex; justify-content: center; align-self: center">
          <el-button size="mini" type="primary" plain @click="close()">取 消</el-button>
          <el-button size="mini" v-if="title != '查看'" type="primary" @click="getHtml()">
            确 定
          </el-button>
        </div>
      </div>
      <div class="content" v-if="indexNumber == 2">
        <el-table :data="list" ref="multipleTable" max-height="800px">
          <el-table-column align="center" prop="ccusName" label="发布人">
          </el-table-column>
          <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
          <el-table-column align="center" prop="content" label="发布内容">
          </el-table-column>
          <el-table-column align="center" prop="content" label="发布图片">
            <template slot-scope="scope">
              <el-image
                v-for="(image, index) in scope.row.creationcenterImageList"
                :key="index"
                :src="image.url"
                :preview-src-list="allImageUrls(scope.row.creationcenterImageList)"
                @mouseover="previewIndex = index"
                style="width: 50px; height: 50px; margin: 5px; cursor: zoom-in"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="发布时间">
          </el-table-column>
          <el-table-column align="center" prop="errorMessage" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)">
                查看详情并回复
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-image-viewer
          :url-list="previewUrlList"
          :initial-index="previewIndex"
          @close="previewIndex == null"
          v-if="previewUrlList.length > 0"
        >
        </el-image-viewer>

        <el-pagination
          style="margin-top: 10px"
          background
          :current-page="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          :layout="layout"
          :total="total"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div class="ft"></div>
    </div>
    <detail ref="detail"></detail>
  </div>
</template>

<script>
import shopOrder from "./shopOrder/index.vue";
import ShopItem from "./Shoping/ShopItemOrder.vue";
import detail from "./coCreationCenterDetail/Detail.vue";
import {
  dcShoppingmallCreationcenter,
  dcShoppingmallCreationcenterAdd,
} from "@/api/other/index.js";

export default {
  name: "ShoppingVue",
  data() {
    return {
      form: {},
      options: [
        {
          value: "选项1",
          label: "非洲",
        },
        {
          value: "选项2",
          label: "迪拜",
        },
        {
          value: "选项3",
          label: "中东",
        },
        {
          value: "选项4",
          label: "广州",
        },
        {
          value: "选项5",
          label: "菲力宾",
        },
      ],
      list: [],
      indexNumber: "1",
      activeIndex: [],
      title: [],
      dialogImageUrl: [],
      imagedialogVisible: false,
      dialogVisibles: false,
      brandName: this.$route.query.brandName,
      organizationNames: localStorage.getItem("organizationNames"),
      fileList: [],
      fileListCopy: [],
      disabled: false,
      imagelist: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
      },
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      previewUrlList: [],
      previewIndex: 0,
    };
  },
  components: {
    ShopItem,
    shopOrder,
    detail,
  },
  computed: {},
  created() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
      this.listLoading = true;
      let params = {
        ...this.queryForm,
        brandName: this.$route.query.brandName,
        // organizationId: this.organizationName == "广州" ? 1 : 2,
        organizationId: 1,
      };
      dcShoppingmallCreationcenter(params).then((res) => {
        this.list = res.data.list;
        this.total = res.data.total;
        this.list.forEach((itemContent) => {
          itemContent.creationcenterImageList = itemContent.creationcenterImageList.map(
            (item) => {
              return {
                ...item,
                url: this.$locationUrl + "file/" + item.fileUrl,
              };
            }
          );
        });
      });
    },
    detailShow(row) {
      this.$refs.detail.show(row);
    },
    handleSelect(index) {
      this.indexNumber = index;
    },
    close() {
      this.form.remark = [];
      this.form.value = [];
      this.form.image = [];
    },
    // 新增
    getHtml() {
      // this.form.ccusCode = this.list[0].ccusCode;

      this.fileData = new FormData();
      if (this.form.remark) this.fileData.append("content", this.form.remark);
      if (this.form.ccusCode) this.fileData.append("ccusCode", this.form.ccusCode);
   
      this.$refs.upload.submit();
      dcShoppingmallCreationcenterAdd(this.fileData).then((res) => {
        this.loading = false;
        if (res.code === 200) {
        
          this.$emit("init");
          this.fileListCopy = this.fileList;
          this.form.remark = [];
          this.init()
        }
      });
    },
    handleProgress(event, file, fileList) {
      // this.loading = true;
      // this.show = true;
    },
    uploadFile(file) {
      // this.fileData.append("fileList", file.file);
    },
    // 上传
    uploadFileList(file) {
      // this.fileListCopy.map((file) => {
      //   // console.log(file.uid);
      //   file.uid = file.uid;
      // });
      // this.fileListCopy.push(file.file);
      this.fileData.append("image", file.file);
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url ? file.url : file.raw.path;
      this.imagedialogVisible = true;
    },
    // 移除
    handleRemove(file, fileListCopy) {
      this.fileListCopy.map((item, index) => {
        if (item.uid == file.uid) {
          this.fileListCopy.splice(index, 1);
        }
      });
    },
    beforeUpload(file) {
      return true;
    },
    handleFileChange(file, fileList) {
      this.fileListCopy = fileList;
    },
    handleCurrentChange(current) {
      this.queryForm.pageNum = current;
      this.init();
    },
    handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.init();
    },
    allImageUrls(imageList) {
      return imageList.map((img) => img.url);
    },
    selectImage(currentIndex, imageList) {
      this.previewUrlList = imageList.map((img) => img.url);
      this.previewIndex = currentIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

.shopping {
  width: 100vw;
  background: repeat-y
    url("https://www.gqedu.org.cn:6868/manage/716bbd2b57c841ccbac91d12a4ea907d.png");
  background-size: cover;

  .content {
    background-color: #fff;
    border-radius: 24px;
    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 44px;
    height: $h;
    margin-bottom: 15px;
    background: #f5f5f5;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
</style>
