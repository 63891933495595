<template>
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    width="75%"
    center
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="top" style="margin-bottom: 20px">
      <span class="title"></span>
      <div>流程详情</div>
    </div>
    <div class="block" style="height: 600px; overflow: auto">
      <el-timeline>
        <div v-for="(item, index) in list" :key="index">
          <el-timeline-item :timestamp="item.createTime" placement="top">
            <el-card>
              <h4 v-if="item.createrType == 1">客户：{{ item.creater }}</h4>
              <h4 v-else>客服：{{ item.creater }}</h4>
              <p v-if="item.content">{{ item.content }}</p>
              <el-image
                v-if="item.url"
                :src="item.url"
                style="width: 50px; height: 50px"
                :preview-src-list="[item.url]"
              />
              <!-- <div>{{ item.createdDate }}</div> -->
            </el-card>
          </el-timeline-item>
        </div>
      </el-timeline>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisibles = true" size="mini" type="primary">
        回复投诉人
      </el-button>
      <el-button @click="dialogVisible = false" size="mini" type="primary">
        取 消
      </el-button>
    </span>

    <el-dialog
      title="回复"
      :visible.sync="dialogVisibles"
      width="720px"
      center
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div style="">
        <el-form class="form" ref="form" :model="form" label-width="auto">
          <el-form-item label="图片" prop="image">
            <div class="demo-image__preview">
              <el-upload
                class="upload-demo"
                style="max-width: 800px"
                name="fileList"
                action="#"
                :with-credentials="true"
                :file-list="fileListCopy"
                accept=".jpg,.jpeg,.png,.JPG,.JPEG,.mp4,.webp"
                list-type="picture-card"
                ref="upload"
                :http-request="uploadFileList"
                :on-progress="handleProgress"
                :auto-upload="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-change="handleFileChange"
                :limit="1"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    :key="file.uid"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="imagedialogVisible">
                <img width="100%" style="z-index: 999" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form-item>
          <el-form-item label="备注" prop="content" style="width: 100%">
            <el-input
              v-model="form.remark"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10 }"
              placeholder="请输入内容"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="clickOver">回复</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
// import { GetzfReply, AddzfReply } from "../../../api/room.js";
import {
  dcShoppingmallCreationcenterDetail,
  addReceipt,
  addContet,
} from "@/api/other/index.js";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      imagedialogVisible: false,
      dialogImageUrl: [],
      disabled: false,
      list: [],
      form: {},
      queryForm: {
        pageNum: 1,
        pageSize: 999,
      },
      fileList: [],
      fileData: new FormData(),
      fileListCopy: [],
    };
  },
  // created() {
  //   this.dcShoppingmallCreationcenterDetail();
  // },
  methods: {
    show(row) {
      // let params = {
      //   ...this.queryForm,
      //   creationId: this.form.creationId,
      // };
      this.form = { ...row };
      this.dcShoppingmallCreationcenterDetail();
      // dcShoppingmallCreationcenterDetail(params).then((res) => {
      //   if (res.code == 200) {
      //     this.list = res.data.list;
      //   }
      // });
      this.dialogVisible = true;
    },
    dcShoppingmallCreationcenterDetail() {
      let params = {
        ...this.queryForm,
        creationId: this.form.creationId,
      };
      dcShoppingmallCreationcenterDetail(params).then((res) => {
        if (res.code == 200) {
          this.list = res.data.list;
          this.list.forEach((item) => {
            if (item.fileUrl) {
              item.url = this.$locationUrl + "file/" + item.fileUrl;
            }
          });
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    // uploadContent() {
    //   let params = {
    //     ...this.queryForm,
    //     creationId: this.form.creationId,
    //   };
    //   addReceipt(params).then((res) => {
    //     this.loading = false;
    //     if (res.code === 200) {
    //       // this.$baseMessage("保存成功", "success");
    //       // this.dialogFormVisible = false;
    //       this.$emit("init");
    //     }
    //   });
    // },
    // uploadImage() {
    //   let params = {
    //     ...this.queryForm,
    //     creationId: this.form.creationId,
    //   };
    //   addImage(params).then((res) => {
    //     this.loading = false;
    //     if (res.code === 200) {
    //       // this.$baseMessage("上传成功", "success");
    //       // this.dialogFormVisible = false;
    //       this.$emit("init");
    //     }
    //   });
    // },
    // 回复
    clickOver() {
      this.loading = true;
      // this.fileData = new FormData();
      if (this.form.remark) this.fileData.append("content", this.form.remark);
      // if (this.form.image) this.fileData.append("fileUrl", this.form.image);
      // this.fileData.append("list", this.fileListCopy);
      if (this.form.creationId) this.fileData.append("creationId", this.form.creationId);
      this.$refs.upload.submit();
      addReceipt(this.fileData).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          // this.$baseMessage("提交成功", "success");
          this.dialogVisibles = false;
          this.$emit("init");
          this.fileListCopy = this.fileList;
          this.form.remark = [];
          this.fileData = new FormData();
        }
        this.dcShoppingmallCreationcenterDetail();
      });
    },
    handleProgress(event, file, fileList) {
      this.loading = true;
      this.show = true;
    },
    uploadFile(file) {
      // this.fileData.append("fileList", file.file);
    },
    // 上传
    uploadFileList(file) {
      // this.fileListCopy.forEach((file) => {
      //   // console.log(file.uid);
      //   file.uid = file.uid;
      // });
      // this.fileListCopy.push(file.file);
      this.fileData.append("image", file.file);
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url ? file.url : file.raw.path;
      this.imagedialogVisible = true;
    },
    // 移除
    handleRemove(file, fileListCopy) {
      this.fileListCopy.map((item, index) => {
        if (item.uid == file.uid) {
          this.fileListCopy.splice(index, 1);
        }
      });
    },
    handleFileChange(file, fileList) {
      this.fileListCopy = fileList;
    },
  },
};
</script>

<style scoped>
.no-received-every-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.no-received-every-top-Price {
  font-size: 18px;
  color: #092553;
  letter-spacing: -0.54px;
  font-weight: 900;
}

.no-received-every-top-amount {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  color: #8994af;
  margin-right: 10px;
  padding: 10px 0;
}

.overdue {
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  background: rgba(249, 21, 21, 0.08);
  border-radius: 4px;
  font-size: 12px;
  color: #f91515;
  margin-left: 10px;
}

.no-received-every-top-name {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
}

.no-received {
  border: 2px solid #f2f4f8;
  margin-bottom: 10px;
}

.no-received-every-top {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 14px;
  color: #092553;
  background-color: #f2f4f8;
  height: 60px;
  line-height: 60px;
}

.box {
  width: 50%;
  font-size: 14px;
  color: #627393;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
}

.box-active {
  width: 100%;
  font-size: 14px;
  color: #627393;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}

.table-header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: SourceHanSansCN-Medium;
  color: #092553;
  font-weight: 600;
  font-size: 16px;
}

.table-header-title {
  border-radius: 2.5px;
  width: 5px;
  height: 16px;
  margin-right: 15px;
  background-color: #28bd9c;
}

.flex {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.pay {
  color: #22ab8c;
  background-color: #eefaf7;
}

.Late {
  background-color: #ffeded;
  color: #f91515;
}

.center {
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
}

::v-deep .el-dialog__title {
  font-size: 18px;
  color: #092553;
  position: relative;
  font-weight: 700;
}

.content {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.top {
  width: 100%;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  background-color: #f2f4f8;
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-family: SourceHanSansCN-Medium;
  color: #092553;
  font-weight: 500;
  font-size: 18px;
}

.title {
  border-radius: 2.5px;
  width: 5px;
  height: 16px;
  margin-right: 15px;
  background-color: #28bd9c;
}

::v-deep .el-card {
  padding: 0;
}

.el-card h4 {
  margin: 0;
  padding-bottom: 5px;
}

.el-card p {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

.el-image {
  margin: 0;
  padding-top: 5px;
  object-fit: cover;
}
</style>
