<template>
    <div>
        <div class="detail" style="width:1200px;margin:0 auto;">
            <div class="tb-item-info home-width com-margin-center com-flex"
                style="display: flex;justify-content: space-between;"><!-- 左侧放大镜区域 -->
                <div class="previewWrap">
                    <!--放大镜效果-->
                    <Zoom :ImgSrc="currentItem.img" />
                    <!-- 小图列表 -->
                    <ImgList :ImgList="currentItem.detail" @getIndex="changeImage" style="margin-top: 46px;" />
                </div>
                <InfoDetail :info="currentItem" ></InfoDetail>
            </div>
            <div>
                
                <div>
                    <div class="section4">
                        <span>商品规格</span>
                    </div>
                    <div class="billList" style="padding: 20px">
                        <div class="item" style="width: 1200px;margin-bottom: 25px;">

                            <div class="content" style="	width: 1200px;font-size: 26px;">
                                <div class="items"
                                    style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;border-top: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            产品名称
                                        </div>
                                        <div class="msg"
                                            style="width: 810px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            前台
                                        </div>
                                    </div>
                                </div>
                                <div class="items" style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            产品货号
                                        </div>
                                        <div class="msg"
                                            style="width: 800px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            EK012211116
                                        </div>
                                    </div>
                                </div>
                                <div class="items" style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            产品颜色
                                        </div>
                                        <div class="msg"
                                            style="width: 800px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            黑色、咖啡、绿色、米色、棕色
                                        </div>
                                    </div>
                                </div>
                                <div class="items" style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            产品材质
                                        </div>
                                        <div class="msg"
                                            style="width: 800px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            木质
                                        </div>
                                    </div>
                                </div>
                                <div class="items" style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            测量尺寸
                                        </div>
                                        <div class="msg"
                                            style="width: 800px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            2m*1.5m*1.2m
                                        </div>
                                    </div>
                                </div>
                                <div class="items" style="width: 1200px;display: flex;border-left: 1px solid #e8e8e8;">
                                    <div class="cell" style="	width: 1200px;display: flex;">
                                        <div class="title"
                                            style="width: 400px;padding: 15px 0;text-align: center;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;font-weight: bold;">
                                            产品重量
                                        </div>
                                        <div class="msg"
                                            style="width: 800px;text-align: center;padding: 15px 0;border-right: 1px solid #e8e8e8;border-bottom: 1px solid #e8e8e8;">
                                            150KG
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="section4">
                <span>商品详情</span>
            </div>
        </div>

    </div>
</template>

<script>
import Zoom from './Detail/Zoom.vue'
import ImgList from './Detail/ImgList.vue'
import InfoDetail from './Detail/infoDetailImage1.vue'
import InfoDetailOther from './Detail/infoDetailOther.vue'
import { mapState } from 'vuex'
export default {
    name: 'DetailPrd',
    data() {
        return {
            isHome: false,
            skuId: undefined,
            skuNum: 1,
            currentItem: {
                "id": 1,
                "ishot": false,
                "img": "https://www.gqedu.org.cn:6868/manage/f99006cfe5ca4a4cb0d87e414a1f3934.png",
                "content": "前台",
                "price": "899",
                "detail": [
                    "https://www.gqedu.org.cn:6868/manage/f99006cfe5ca4a4cb0d87e414a1f3934.png",
                    
                ]
            },
          
        }
    },
    // computed: {
    //     ...mapState(['currentItem'])
    // },
    mounted() {
        document.title = this.currentItem.content
        // 当前商品为空，跳装到首页
        if (Object.keys(this.currentItem).length === 0) {
            this.$router.push({ name: 'home' })
        }
    },
    components: {
        Zoom,
        ImgList,
        InfoDetail,
        InfoDetailOther
    },
    methods: {
        changeImage(v) {
            console.log(v)
            let that = this
            this.$nextTick(() => {
                that.$set(that.currentItem, 'img', v)
            })


        },
    },
}
</script>

<style lang="scss" scoped>
.tb-item-info {
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    justify-content: space-between;
}

.mask {
    z-index: 10;
    top: -8px;
    right: 15px;
    width: 30px;
    height: 21px;
    text-align: center;
    background-color: #fff;
}

.section4 {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
    background: #fff;

    &>span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            display: inline-block;
            background: -webkit-gradient(linear, left top, right top, from(rgba(255, 166, 0, .929412)), to(#ff6a00));
            background: linear-gradient(90deg, rgba(255, 166, 0, .929412), #ff6a00);
            width: 40%;
            border-radius: 50px;
            height: 4px;
        }
    }

    .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
            border: 1px solid #eee;
            padding: 10px 30px;
            margin: 10px 0;
            width: 44%;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 5px 1px #dcdfe6;

                .title a {
                    color: #ff6a00;
                }
            }

            .title {
                display: -webkit-box;
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                margin-bottom: 10px;
            }

            .summary {
                color: #767676;
                font-size: 14px;
            }

            .title a {
                font-size: 18px;
                color: #333;
            }

            .title {
                p {
                    color: #999;
                }
            }
        }
    }
}

.table-header {
    width: 92%;
    margin-left: 6%;
    margin-right: 2%;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN-Medium;
    color: #092553;
    font-weight: 900;
    font-size: 19px;
}

.table-header-title {
    border-radius: 2.5px;
    width: 5px;
    height: 16px;
    margin-right: 15px;
    background-color: #28bd9c;
}

.billList {
    padding: 20px;


    .item {

        margin-bottom: 25px;

        .name {

            padding: 15px 0;
            font-weight: bold;
            text-align: center;
            border: 1px solid #e8e8e8;
            font-size: 30px;
        }

        .content {

            font-size: 26px;

            .items {

                display: flex;
                border-left: 1px solid #e8e8e8;

                .cell {
                    width: 355px;
                    display: flex;

                    .title {
                        width: 150px;
                        padding: 15px 0;
                        text-align: center;
                        border-right: 1px solid #e8e8e8;
                        border-bottom: 1px solid #e8e8e8;
                        font-weight: bold;

                    }

                    .msg {
                        width: 205px;
                        text-align: center;
                        padding: 15px 0;
                        border-right: 1px solid #e8e8e8;
                        border-bottom: 1px solid #e8e8e8;
                    }
                }
            }
        }
    }
}
</style>