<template>
  <div id="indexThree">
    <div class="banner"></div>
    <div v-if="hotgoodsList.length != 0">
      <!-- <div> -->
      <div
        style="display: flex; align-items: center; width: 100%; margin-top: 72px"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img v-if="item.hotSellImageUrl" :src="item.hotSellImage" style="width: 100%" />
        <img
          v-else
          :src="
            brandName === 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-06.jpg'
              : brandName === 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-Hot.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-Hot.jpg'
          "
          style="width: 100%"
        />
      </div>
      <div class="main wrap" style="display: flex; justify-content: center">
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <div
              class="new_goods_title_hot"
              style="display: flex; justify-content: center; width: 100%"
            >
              <span
                style="
                  font-size: 40px;
                  line-height: 28px;

                  font-weight: bold;
                "
                v-if="lange == '中文'"
                >热销商品</span
              >
              <span
                style="
                  font-size: 40px;
                  line-height: 28px;

                  font-weight: bold;
                "
                v-else
                >Hot-Sellers</span
              >
            </div>

            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div
                class="item fl"
                v-for="(item, index) in hotgoodsList"
                style="margin-bottom: 60px"
                :key="index"
              >
                <dl>
                  <dt @click="buyGoods(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </dt>

                  <div class="title" v-if="item.shoppingMallName">
                    {{
                      item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName
                    }}
                  </div>
                  <div class="title" v-else>
                    {{ item.brandName + "-" + item.cinvCode }}
                  </div>
                  <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                    <span class="price fl" v-if="organizationNames == '迪拜'"
                      >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                    >
                    <span class="price fl" v-else
                      >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                    >
                  </div>
                  <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                    <div @click="deleteMultipe(item)">
                      <span class="btn" v-if="lange == '中文'">加入购物车</span>
                      <span class="btn" v-else>Add To Cart</span>
                    </div>
                    <div @click="buyGoods(item)" style="margin-left: 24px">
                      <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                        >立即购买</span
                      >
                      <span class="btns" style="margin-right: 15px" v-else
                        >Order Now</span
                      >
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center" @click="goToHotGood()">
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
      </div>
    </div>
    <div v-if="hotgoodsListtui.length != 0">
      <!-- <div> -->
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >首推商品</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
          >Recommended Product</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img
          v-if="item.recommendImageUrl"
          :src="item.recommendImage"
          style="width: 100%"
        />
        <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-Tui.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-Tui.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-Tui.jpg'
          "
          style="width: 100%"
        />
      </div>
      <div class="main wrap" style="display: flex">
        <div style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap">
          <div>
            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div class="item fl" v-for="(item, index) in hotgoodsListtui" :key="index">
                <dl>
                  <dt @click="buyGoodProductingCommodityTui(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </dt>
                  <div class="title" v-if="item.shoppingMallName">
                    {{
                      item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName
                    }}
                  </div>
                  <div class="title" v-else>
                    {{ item.brandName + "-" + item.cinvCode }}
                  </div>
                  <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                    <span class="price fl" v-if="organizationNames == '迪拜'"
                      >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                    >
                    <span class="price fl" v-else
                      >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                    >
                  </div>
                  <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                    <div @click="deleteMultipeTui(item)">
                      <span class="btn" v-if="lange == '中文'">加入购物车</span>
                      <span class="btn" v-else>Add To Cart</span>
                    </div>
                    <div
                      @click="buyGoodProductingCommodityTui(item)"
                      style="margin-left: 24px"
                    >
                      <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                        >立即购买</span
                      >
                      <span class="btns" style="margin-right: 15px" v-else
                        >Order Now</span
                      >
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center" @click="goToTuiGood()">
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
      </div>
    </div>

    <div v-if="hotgoodsListnew.length != 0">
      <!-- <div> -->
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >新品预售</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
          >New Arrival</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img
          v-if="item.newCommodityUrl"
          :src="item.newCommodityImage"
          style="width: 100%"
        />
        <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-07.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-New.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-New.jpg'
          "
          style="width: 100%"
        />
      </div>
      <div class="main wrap" style="display: flex">
        <div style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap">
          <div style="width: 100%">
            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div class="item fl" v-for="(item, index) in hotgoodsListnew" :key="index">
                <dl>
                  <dt @click="buyGoodNew(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </dt>
                  <div class="title" v-if="item.shoppingMallName">
                    {{
                      item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName
                    }}
                  </div>
                  <div class="title" v-else>
                    {{ item.brandName + "-" + item.cinvCode }}
                  </div>
                  <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                    <span class="price fl" v-if="organizationNames == '迪拜'"
                      >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                    >
                    <span class="price fl" v-else
                      >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                    >
                  </div>
                  <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                    <div @click="deleteMultipeNew(item)">
                      <span class="btn" v-if="lange == '中文'">加入购物车</span>
                      <span class="btn" v-else>Add To Cart</span>
                    </div>
                    <div @click="buyGoodNew(item)" style="margin-left: 24px">
                      <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                        >立即购买</span
                      >
                      <span class="btns" style="margin-right: 15px" v-else
                        >Order Now</span
                      >
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center" @click="goToNewGood()">
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
      </div>
    </div>
    <div v-if="ProductingCommodity.length != 0">
      <!-- <div> -->
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >在产预售</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
        >
          Pre-Sale In Production</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img
          v-if="item.newCommodityUrl"
          :src="item.newCommodityImage"
          style="width: 100%"
        />
        <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-08.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-product.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-product.jpg'
          "
          style="width: 100%"
        />
      </div>
      <div class="main wrap" style="display: flex">
        <div style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap">
          <div style="width: 100%">
            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div
                class="item fl"
                v-for="(item, index) in ProductingCommodity"
                :key="index"
              >
                <dl>
                  <dt @click="buyGoodProductingCommodity(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </dt>
                  <div class="title" v-if="item.shoppingMallName">
                    {{
                      item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName
                    }}
                  </div>
                  <div class="title" v-else>
                    {{ item.brandName + "-" + item.cinvCode }}
                  </div>
                  <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                    <span class="price fl" v-if="organizationNames == '迪拜'"
                      >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                    >
                    <span class="price fl" v-else
                      >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                    >
                  </div>
                  <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                    <div @click="deleteMultipeProducting(item)">
                      <span class="btn" v-if="lange == '中文'">加入购物车</span>
                      <span class="btn" v-else>Add To Cart</span>
                    </div>
                    <div
                      @click="buyGoodProductingCommodity(item)"
                      style="margin-left: 24px"
                    >
                      <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                        >立即购买</span
                      >
                      <span class="btns" style="margin-right: 15px" v-else
                        >Order Now</span
                      >
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex; justify-content: center"
        @click="goInPproductionPreSale()"
      >
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
      </div>
    </div>
    <div v-if="TransportCommodity.length != 0">
      <!-- <div> -->
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >在途预售</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
        >
          Pre-Sale In Transit</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img
          v-if="item.transportCommodityUrl"
          :src="item.transportCommodityImage"
          style="width: 100%"
        />
        <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-09.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-zaitu.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-zaitu.jpg'
          "
          style="width: 100%"
        />
      </div>
      <div class="main wrap" style="display: flex">
        <div style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap">
          <div style="width: 100%">
            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div
                class="item fl"
                v-for="(item, index) in TransportCommodity"
                :key="index"
              >
                <dl>
                  <dt @click="buyGoodTransportCommodity(item)">
                    <imageSwiper :imgUrl="item.imageUrlList" style="width: 100%">
                    </imageSwiper>
                  </dt>
                  <div class="title" v-if="item.shoppingMallName">
                    {{
                      item.brandName + "-" + item.cinvCode + "-" + item.shoppingMallName
                    }}
                  </div>
                  <div class="title" v-else>
                    {{ item.brandName + "-" + item.cinvCode }}
                  </div>
                  <div class="other clearfix" style="line-height: 36px; font-weight: 600">
                    <span class="price fl" v-if="organizationNames == '迪拜'"
                      >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                    >
                    <span class="price fl" v-else
                      >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                    >
                  </div>
                  <div style="display: flex; margin-top: 8px; padding-bottom: 20px">
                    <div @click="deleteMultipeTransport(item)">
                      <span class="btn" v-if="lange == '中文'">加入购物车</span>
                      <span class="btn" v-else>Add To Cart</span>
                    </div>
                    <div
                      @click="buyGoodTransportCommodity(item)"
                      style="margin-left: 24px"
                    >
                      <span class="btns" style="margin-right: 15px" v-if="lange == '中文'"
                        >立即购买</span
                      >
                      <span class="btns" style="margin-right: 15px" v-else
                        >Order Now</span
                      >
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex; justify-content: center"
        @click="goInPproductionPreSale()"
      >
        <div class="btnMore" v-if="lange == '中文'">查看更多</div>
        <div class="btnMore" v-else>search for more</div>
      </div>
    </div>

    <div v-if="navList.length != 0">
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >形象店铺</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
          >Standard Store</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img v-if="item.storeImageUrl" :src="item.storeImage" style="width: 100%" />
        <!-- <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-Tui.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-Tui.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-Tui.jpg'
          "
          style="width: 100%"
        /> -->
      </div>
      <div class="main wrap" style="display: flex">
        <div style="width: 100%">
          <div style="width: 100%">
            <div style="width: 80%; margin: 0% auto">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="itemCount.size"
                  :name="itemCount.storeId.toString()"
                  v-for="(itemCount, index) in navList"
                  :key="index"
                >
                  <div
                    class="recommend clearfix"
                    style="
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      flex-wrap: wrap;
                    "
                  >
                    <div>
                      <div class="itemShop fl">
                        <div style="margin-right: 1%; width: 49%">
                          <video
                            ref="videoPlayer"
                            controls
                            autoplay="false"
                            loop
                            style="object-fit: cover; width: 100%;height: 500px"
                          >
                            <source
                              :src="itemCount.storeMainImageList[0].imageUrl"
                              type="video/mp4"
                              style="width: 100%; height: 100%"
                            />
                          </video>
                        </div>
                        <div style="margin-left: 1%; width: 49%">
                          <img
                            :src="itemCount.storeSecondaryImageList[0].imageUrl"
                            alt=""
                            style="width: 100%;height: 500px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main wrap" style="display: flex; width: 100%">
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          width: 100%;
                          flex-wrap: wrap;
                        "
                      >
                        <div style="width: 100%">
                          <div
                            class="recommend clearfix"
                            style="
                              display: flex;
                              justify-content: center;
                              width: 100%;
                              flex-wrap: wrap;
                            "
                          >
                            <div
                              class="item fl"
                              v-for="(item, index) in itemCount.storeCommList"
                              :key="index"
                            >
                              <dl>
                                <dt @click="buyGoodProductingCommodityTui(item)">
                                  <imageSwiper
                                    :imgUrl="item.imageUrlList"
                                    style="width: 100%"
                                  >
                                  </imageSwiper>
                                </dt>
                                <div class="title" v-if="item.shoppingMallName">
                                  {{
                                    item.brandName +
                                    "-" +
                                    item.cinvCode +
                                    "-" +
                                    item.shoppingMallName
                                  }}
                                </div>
                                <div class="title" v-else>
                                  {{ item.brandName + "-" + item.cinvCode }}
                                </div>
                                <div
                                  class="other clearfix"
                                  style="line-height: 36px; font-weight: 600"
                                >
                                  <span
                                    class="price fl"
                                    v-if="organizationNames == '迪拜'"
                                    >{{ parseFloat(item.price).toFixed(2) }}DHS/PCS</span
                                  >
                                  <span class="price fl" v-else
                                    >{{ parseFloat(item.price).toFixed(2) }}RMB/PCS</span
                                  >
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    margin-top: 8px;
                                    padding-bottom: 20px;
                                  "
                                >
                                  <div @click="deleteMultipeTui(item)">
                                    <span class="btn" v-if="lange == '中文'"
                                      >加入购物车</span
                                    >
                                    <span class="btn" v-else>Add To Cart</span>
                                  </div>
                                  <div
                                    @click="buyGoodProductingCommodityTui(item)"
                                    style="margin-left: 24px"
                                  >
                                    <span
                                      class="btns"
                                      style="margin-right: 15px"
                                      v-if="lange == '中文'"
                                      >立即购买</span
                                    >
                                    <span class="btns" style="margin-right: 15px" v-else
                                      >Order Now</span
                                    >
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style="display: flex; justify-content: center"
                      @click="goXXDView(itemCount)"
                    >
                      <div class="btnMore" v-if="lange == '中文'">查看更多商品</div>
                      <div class="btnMore" v-else>search for more Good</div>
                    </div>
                  </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="配置管理" name="second"></el-tab-pane>
                <el-tab-pane label="角色管理" name="third"></el-tab-pane>
                <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane> -->
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hotgoodsListCopysCB.length != 0">
      <div
        class="new_goods_title_hots"
        style="display: flex; justify-content: center; width: 100%"
      >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-if="lange == '中文'"
          >营销活动</span
        >
        <span
          style="
            font-size: 40px;
            line-height: 28px;

            font-weight: bold;
          "
          v-else
          >Campaigns</span
        >
      </div>
      <div
        style="display: flex; align-items: center; width: 100%"
        v-for="item in imageList"
        :key="item.brandName"
      >
        <img
          v-if="item.marketingActUrl"
          :src="item.marketingActImage"
          style="width: 100%"
        />
        <!-- <img
          v-else
          :src="
            brandName == 'CHRISBELLA'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/CB-Tui.jpg'
              : brandName == 'SUSEN零售'
              ? 'http://202.96.158.115:8099/file/abyFile/backGround/SUSEN-Tui.jpg'
              : 'http://202.96.158.115:8099/file/abyFile/backGround/BC-Tui.jpg'
          "
          style="width: 100%"
        /> -->
      </div>
      <div class="main wrap" style="display: flex">
        <div style="width: 100%">
          <div style="width: 100%">
            <div
              class="recommend clearfix"
              style="display: flex; justify-content: center; width: 100%; flex-wrap: wrap"
            >
              <div class="itemShop1 fl" v-for="item in hotgoodsListCopysCB">
                <dl @click="activityDetail(item)">
                  <dt style="position: relative; margin: 1%">
                    <img
                      :src="item.cover | fullPath"
                      alt=""
                      style="width: 100%; aspect-ratio: 1/1; height: auto"
                    />
                    <div
                      style="
                        position: absolute;
                        bottom: 0;
                        line-height: 64px;
                        background: black;
                        opacity: 0.5;
                        width: 100%;
                        color: white;
                        text-align: center;
                        font-size: 40px;
                      "
                    >
                      {{ item.noticename }}
                    </div>
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <addCartDialog
      ref="addCartDialog"
      v-if="addCartDialogShow"
      @back="addCartDialogShowClick"
    />
    <addCartDialogNew
      ref="addCartDialogNew"
      v-if="addCartDialogNewShow"
      @back="addCartDialogNewShowClick"
    />
    <addCartDialogTui
      ref="addCartDialogTui"
      v-if="addCartDialogTuiShow"
      @back="addCartDialogTuiShowClick"
    />
    <addCartDialogProducting
      ref="addCartDialogProducting"
      v-if="addCartDialogProductingShow"
      @back="addCartDialogProductingShowClick"
    />
    <addCartDialogTransport
      ref="addCartDialogTransport"
      v-if="addCartDialogTransportShow"
      @back="addCartDialogTransportShowClick"
    />
  </div>
</template>

<script>
import {
  hostGood,
  getRecommendCommodityPage,
  getProductingCommodityPage,
  // getTransportCommodityPage,
  getDcBasearchiveCommodity,
  getDcBasearchiveCommodityFile,
  getNewCommodityPage,
  getQueryActivity,
  getDcShoppingmallStore,
  getDcShoppingmallHeadimage,
} from "@/api/good/index.js";
import { mapGetters } from "vuex";
import imageSwiper from "@/components/imageSwiper/index.vue";
import Zoom from "./Detail/Zoom.vue";
import ImgList from "./Detail/ImgList.vue";
import InfoDetail from "./Detail/InfoDetail.vue";
import addCartDialog from "./addCartDialog/index.vue";
import addCartDialogNew from "./addCartDialog/newIndex.vue";
import addCartDialogTui from "./addCartDialog/tuiIndex.vue";
import addCartDialogProducting from "./addCartDialog/productingIndex.vue";
import addCartDialogTransport from "./addCartDialog/transportIndex.vue";
import shopOrder from "./shopOrder/indexOther.vue";
export default {
  name: "GoodsHome",
  components: {
    shopOrder,
    Zoom,
    ImgList,
    InfoDetail,
    imageSwiper,
    addCartDialog,
    addCartDialogNew,
    addCartDialogTui,
    addCartDialogProducting,
    addCartDialogTransport,
  },
  data() {
    return {
      brandName: this.$route.query.brandName,
      addCartDialogShow: true,
      addCartDialogNewShow: true,
      addCartDialogTuiShow: true,
      organizationNames: localStorage.getItem("organizationNames"),
      addCartDialogProductingShow: true,
      addCartDialogTransportShow: true,
      // lange: localStorage.getItem("lange"),
      imageList: [],
      imageIndex: 0,
      isLogin: false,
      autoplayTrue: true,
      articleList: [],
      articleList2: [],
      currentItem: {
        id: 1,
        ishot: false,
        img: "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
        content: "CHRISBELLA-双手腕女包-BG012211222",
        price: "899",
        dialogVisible: false,
        detail: [
          "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
          "https://www.gqedu.org.cn:6868/manage/92914b8fed0149ad803d08c6cc9cee5a.JPG",
          "https://www.gqedu.org.cn:6868/manage/d1c3d0d15bf0485e8bd00a13a8706c6b.JPG",
          "https://www.gqedu.org.cn:6868/manage/72488a01333b4f378d88f51060384aa0.JPG",
          "https://www.gqedu.org.cn:6868/manage/1f17d3fa9fd04cf8a40ec1810805ec02.JPG",
          "https://www.gqedu.org.cn:6868/manage/c57a78ad32224bd386e187446d08d3f3.JPG",
          "https://www.gqedu.org.cn:6868/manage/b03075c6b4054d3a82420bce2de9e448.JPG",
          "https://www.gqedu.org.cn:6868/manage/27b2eb231fd44d039e105271fbb307f7.JPG",
          "https://www.gqedu.org.cn:6868/manage/116ea389b7be494bbc7cb56f68a3672a.JPG",
        ],
      },
      hotgoodsList: [],
      hotgoodsListtui: [],
      hotgoodsListnew: [],
      hotgoodsListCopy: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/10.jpg",
          name: "综合店(500m³)",
          cost: 1000,
        },
      ],
      hotgoodsListCopys: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/12.jpg",
          name: "圣诞节",
          cost: 1000,
        },
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/13.jpg",
          name: "Big Sale Up to 50%",
          cost: 1000,
        },
      ],
      hotgoodsListCopyBC: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/18.jpg",
          name: "综合店(500m³)",
          cost: 1000,
        },
      ],
      hotgoodsListCopysBC: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/20.jpg",
          name: "圣诞节",
          cost: 1000,
        },
      ],
      hotgoodsListCopyCB: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/14.jpg",
          name: "综合店(500m³)",
          cost: 1000,
        },
      ],
      hotgoodsListCopysCB: [
        {
          cover: "http://202.96.158.115:8099/file/abyFile/backGround/16.jpg",
          name: "圣诞节",
          cost: 1000,
        },
      ],
      newestGoodsList: [],
      newestGoodsListg: [],
      ProductingCommodity: [],
      TransportCommodity: [],
      imageHight: 0,
      advertList: [
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/9499266a523640778394bd3d9e70103b.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/cc13dac7e14246f39bb6868029068531.JPG",
        },
        {
          image:
            "https://www.gqedu.org.cn:6868/manage/aa64484d49a04ebfaf662a9bc2242182.JPG",
        },
      ],
      hotNull: 0,
      newNull: 0,
      swiperOptions: {
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      dialogTableVisible: false,
      itemContent: {},
      currentItems: {},
      discount: 1,
      addPrice: 0,
      activeName: "first",
      storeList: [],
      navList: [],
    };
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  created() {
    console.log(this.brandName);
    if (this.priceChangeList.length != 0) {
      if (this.brandName == "CHRISBELLA") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (discountLength.length != 0) {
          if (discountLength[0].discount) {
            this.discount = discountLength[0].discount / 100;
          } else {
            this.discount = 1;
          }
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (addPriceLength.length != 0) {
          if (addPriceLength[0].priceadjustment) {
            this.addPrice = addPriceLength[0].priceadjustment;
          } else {
            this.addPrice = 0;
          }
        }
      } else if (this.brandName == "BAGCO") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (discountLength.length != 0) {
          if (discountLength[0].discount) {
            this.discount = discountLength[0].discount / 100;
          } else {
            this.discount = 1;
          }
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (addPriceLength.length != 0) {
          if (addPriceLength[0].priceadjustment) {
            this.addPrice = addPriceLength[0].priceadjustment;
          } else {
            this.addPrice = 0;
          }
        }
      } else {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN零售";
        });
        if (discountLength.length != 0) {
          if (discountLength[0].discount) {
            this.discount = discountLength[0].discount / 100;
          } else {
            this.discount = 1;
          }
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN零售";
        });
        if (addPriceLength.length != 0) {
          if (addPriceLength[0].priceadjustment) {
            this.addPrice = addPriceLength[0].priceadjustment;
          } else {
            this.addPrice = 0;
          }
        }
      }
    }
    console.log("this.discount", this.discount);
    console.log("this.addPrice", this.addPrice);
    getQueryActivity({ pageNum: 1, pageSize: 4 }).then((res) => {
      this.hotgoodsListCopysCB = res.data.list;
      this.hotgoodsListCopysCB.forEach((item) => {
        item.cover = this.$locationUrl + "file/" + item.imageUrl;
        item.name = "noticename";
        item.cost = 1000;
      });
      console.log(this.hotgoodsListCopysCB);
    });
    getDcShoppingmallHeadimage({
      pageSize: 10,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.imageList = res.data;
      // this.activeName = res.data[0].brandName.toString();

      this.imageList.forEach((item) => {
        // 热销商品
        // item.hotSellList.forEach((item) => {
        item.hotSellImage = this.$locationUrl + "file/" + item.hotSellImageUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // item.hotSellList = item.hotSellList.slice(0, 4);
        // 首推商品
        // item.recommendList.forEach((item) => {
        item.recommendImage = this.$locationUrl + "file/" + item.recommendImageUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // 新品预售
        // item.newCommodityList.forEach((item) => {
        item.newCommodityImage = this.$locationUrl + "file/" + item.newCommodityUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // 在产预售
        // item.productCommodityList.forEach((item) => {
        item.productCommodityImage =
          this.$locationUrl + "file/" + item.productCommodityUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // 在途预售
        // item.transportCommodityList.forEach((item) => {
        item.transportCommodityImage =
          this.$locationUrl + "file/" + item.transportCommodityUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // 形象店铺
        // item.marketingActList.forEach((item) => {
        item.storeImage = this.$locationUrl + "file/" + item.storeImageUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
        // 营销活动
        // item.marketingActList.forEach((item) => {
        item.marketingActImage = this.$locationUrl + "file/" + item.marketingActUrl;
        // item.imageUrlList = [item.imageUrl, item.imageUrl];
        // });
      });
    });
    getDcShoppingmallStore({
      pageSize: 12,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.navList = res.data.list;
      this.activeName = res.data.list[0].storeId.toString();

      this.navList.forEach((item) => {
        item.storeCommList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          item.imageUrlList = [item.imageUrl, item.imageUrl];
        });
        item.storeCommList = item.storeCommList.slice(0, 4);
        item.storeMainImageList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        });
        item.storeSecondaryImageList.forEach((item) => {
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        });
      });
    });
    hostGood({
      pageSize: 12,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.hotgoodsList = res.data.list;
      console.log(this.priceChangeList);

      this.hotgoodsList.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        item.hasEntered = false;
        item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
        console.log(item.imageUrl);
        item.imageUrlList = [item.imageUrl, item.imageUrl1];
        item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
      });
      console.log(this.hotgoodsList);
    });

    getRecommendCommodityPage({
      pageSize: 12,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.hotgoodsListtui = res.data.list;
      this.hotgoodsListtui.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
        item.imageUrlList = [item.imageUrl, item.imageUrl1];
        item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
        console.log(item.imageUrl);
      });
    });
    getNewCommodityPage({
      pageSize: 12,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.hotgoodsListnew = res.data.list;
      this.hotgoodsListnew.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
        item.imageUrlList = [item.imageUrl, item.imageUrl1];
        item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
        console.log(item.imageUrl);
      });
    });
    getProductingCommodityPage({
      pageSize: 12,
      pageNum: 1,
      brandName: this.brandName,
      organizationId: this.organizationNames == "迪拜" ? 2 : 1,
    }).then((res) => {
      this.ProductingCommodity = res.data.list;
      this.ProductingCommodity.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        item.imageUrl1 = this.$locationUrl + "file/" + item.imageUrl1;
        item.imageUrlList = [item.imageUrl, item.imageUrl1];
        item.price = (item.price * 1 + this.addPrice * 1) * this.discount;
        console.log(item.imageUrl);
      });
    });
  },
  // mounted() {this.setImageHeight()},
  watch: {},
  // mounted() {
  //   window.addEventListener("popstate", function (event) {
  //     // 在这里可以调用历史记录页面的方法
  //     // 例如，假设页面实例保存在 app 变量中
  //     hostGood({ pageSize: 10, pageNum: 1 }).then((res) => {
  //       this.hotgoodsList = res.data.list;
  //       this.hotgoodsList.forEach((item) => {
  //         item.imageUrl = "http://192.168.1.12:8099/file/" + item.imageUrl;
  //         console.log(item.imageUrl);
  //       });
  //     });
  //     console.log(this.hotgoodsList);
  //     getRecommendCommodityPage({ pageSize: 10, pageNum: 1 }).then((res) => {
  //       console.log(res);
  //     });
  //     this.$nextTick(() => {
  //       const elements = this.$refs.images;
  //       if (elements) {
  //         let height = 0;
  //         elements.forEach((element) => {
  //           height = element.clientWidth;
  //           this.imageHight = height + "px";
  //         });

  //         console.log("22222", this.imageHight);
  //       }
  //     });
  //   });
  // },
  methods: {
    handleClick(item) {
      console.log(item);
    },
    addCartDialogShowClick() {
      this.addCartDialogShow = false;
      setTimeout(() => {
        this.addCartDialogShow = true;
      }, 500);
    },
    addCartDialogNewShowClick() {
      this.addCartDialogNewShow = false;
      setTimeout(() => {
        this.addCartDialogNewShow = true;
      }, 500);
    },
    addCartDialogTuiShowClick() {
      this.addCartDialogTuiShow = false;
      setTimeout(() => {
        this.addCartDialogTuiShow = true;
      }, 500);
    },
    addCartDialogProductingShowClick() {
      this.addCartDialogProductingShow = false;
      setTimeout(() => {
        this.addCartDialogProductingShow = true;
      }, 500);
    },
    addCartDialogTransportShowClick() {
      this.addCartDialogTransportShow = false;
      setTimeout(() => {
        this.addCartDialogTransportShow = true;
      }, 500);
    },
    changeImage(v) {
      console.log(v);
      let that = this;
      this.$nextTick(() => {
        that.imageIndex = v;
      });
    },
    activityDetail(item) {
      window.open(
        this.$locationViewUrl +
          "#/goods/activityDetail?brandName=" +
          this.brandName +
          "&activityId=" +
          item.noticeid,
        "_blank"
      );
    },
    goToHotGood() {
      window.open(
        this.$locationViewUrl + "#/goods/hotGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goToNewGood() {
      window.open(
        this.$locationViewUrl + "#/goods/newGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goToTuiGood() {
      window.open(
        this.$locationViewUrl + "#/goods/tuiGoodViewCounter?brandName=" + this.brandName,
        "_blank"
      );
    },
    goInTransitPreSale() {
      window.open(
        this.$locationViewUrl + "#/goods/inTransitPreSale?brandName=" + this.brandName,
        "_blank"
      );
    },
    goInPproductionPreSale() {
      window.open(
        this.$locationViewUrl +
          "#/goods/inPproductionPreSale?brandName=" +
          this.brandName,
        "_blank"
      );
    },
    goXXDView(itemCount) {
      window.open(
        this.$locationViewUrl +
          "#/goods/XXDViewCounter?brandName=" +
          this.brandName +
          "&storeId=" +
          itemCount.storeId,
        "_blank"
      );
    },
    handleMouseOver(index, item) {
      console.log(this.$refs.changeImage[index]);
      this.$refs.changeImage[index].style.transform = "translateX(0)";
      this.$refs.changeImage[index].style.visibility = "visible";
      this.$refs.changeImage1[index].style.transform = "translateX(-50%)";
      this.$refs.changeImage1[index].style.visibility = "hidden";
      item.hasEntered = true; // 设置已经执行过了的标记

      console.log(item.hasEntered);
    },
    handleMouseLeave(index, item) {
      this.$refs.changeImage[index].style.transform = "translateX(-50%)";
      this.$refs.changeImage[index].style.visibility = "hidden";
      this.$refs.changeImage1[index].style.transform = "translateX(0)";
      this.$refs.changeImage1[index].style.visibility = "visible";

      item.hasEntered = false;
      console.log(item.hasEntered);
    },
    handleMouseOver1(index, item) {
      console.log(this.$refs.changeImage2[index]);
      this.$refs.changeImage3[index].style.transform = "translateX(0)";
      this.$refs.changeImage3[index].style.visibility = "visible";
      this.$refs.changeImage2[index].style.transform = "translateX(-50%)";
      this.$refs.changeImage2[index].style.visibility = "hidden";
      item.hasEntered = true; // 设置已经执行过了的标记

      console.log(item.hasEntered);
    },
    handleMouseLeave1(index, item) {
      this.$refs.changeImage3[index].style.transform = "translateX(-50%)";
      this.$refs.changeImage3[index].style.visibility = "hidden";
      this.$refs.changeImage2[index].style.transform = "translateX(0)";
      this.$refs.changeImage2[index].style.visibility = "visible";

      item.hasEntered = false;
      console.log(item.hasEntered);
    },
    itemChangeImage(item) {
      item.imageUrl =
        "http://202.96.158.115:8099/file/abyFile/commodityFile/commodityImage/54d9d4f1-9ef6-4d27-955a-7d0c33490cca.jpg";
    },
    setImageHeight(index) {
      const imageElement = this.$refs.image[index];
      const imageWrapperElement = this.$refs.imageWrapper[index];

      const width = imageWrapperElement.offsetWidth;
      console.log(width);
      imageElement.style.height = `${width}px`;
    },
    changeImage(v) {
      console.log(v);
      let that = this;
      this.$nextTick(() => {
        that.$set(that.currentItem, "img", v);
      });
    },
    deleteMultipe(item) {
      this.$refs.addCartDialog.show(item.cinvCode);
    },
    deleteMultipeNew(item) {
      this.addCartDialogNewShow = true;
      this.$refs.addCartDialogNew.show(item.cinvCode);
    },
    deleteMultipeTui(item) {
      this.addCartDialogTuiShow = true;
      this.$refs.addCartDialogTui.show(item.cinvCode);
    },
    deleteMultipeProducting(item) {
      this.addCartDialogProductingShow = true;
      this.$refs.addCartDialogProducting.show(item.cinvCode);
    },
    deleteMultipeTransport(item) {
      this.addCartDialogTransportShow = true;
      this.$refs.addCartDialogTransport.show(item.cinvCode);
    },
    changselect(index, indexPath) {
      if (index == 1) {
        const targetDiv = document.querySelector(".new_goods_title_hot");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 2) {
        const targetDiv = document.querySelector(".new_goods_title_new");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 3) {
        const targetDiv = document.querySelector(".new_goods_title_new1");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 4) {
        const targetDiv = document.querySelector(".new_goods_title_new2");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else if (index == 6) {
        const targetDiv = document.querySelector(".new_goods_title_Image_store");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      } else {
        const targetDiv = document.querySelector(".section4");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_index");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    //公告列表
    getArticle() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 3,
          type: 1,
        },
        success: (res) => {
          this.articleList = res[0];
        },
      });
    },
    //新闻列表
    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/newlists",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
        },
        success: (res) => {
          this.articleList2 = res[0];
        },
      });
    },
    //热销商品列表
    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.hotgoodsList = res[0];
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },
    //最新商品
    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goodlist",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 5,
          tenantid: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.newestGoodsList = res[0];
          if (this.newestGoodsList.length < 11) {
            this.newNull = 11 - this.newestGoodsList.length;
          }
        },
      });
    },
    buyGoods(item) {
      console.log(11111);
      window.open(
        this.$locationViewUrl +
          "#/goods/Detail?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName,
        "_blank"
      );
    },
    buyGoodNew(item) {
      window.open(
        this.$locationViewUrl +
          "#/goods/DetailNew?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName,
        "_blank"
      );
    },
    buyGoodProductingCommodity(item) {
      window.open(
        this.$locationViewUrl +
          "#/goods/DeatilProductingCommodity?cinvCode=" +
          item.cinvCode +
          "&productingCommodityId=" +
          item.productingCommodityId +
          "&brandName=" +
          this.brandName,
        "_blank"
      );
    },
    buyGoodProductingCommodityTui(item) {
      window.open(
        this.$locationViewUrl +
          "#/goods/DetailTui?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName,
        "_blank"
      );
    },
    buyGoodTransportCommodity(item) {
      window.open(
        this.$locationViewUrl +
          "#/goods/DeatilTransportCommodity?cinvCode=" +
          item.cinvCode +
          "&transportCommodityId=" +
          item.transportCommodityId +
          "&brandName=" +
          this.brandName,
        "_blank"
      );
    },
    buyGood(item) {
      // this.$store.commit('getGoodsInfo', item)

      this.$router.push({
        path: "/goods/ImageGoodList",
        query: { brandName: this.brandName },
      });
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert",
        params: {
          type: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.advertList = res;
          //consloe.log(res);
        },
        finally: () => {},
      });
    },
    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.open(url);
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>
<style>
.btnMore {
  line-height: 28px;
  text-align: center;
  color: black;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 30px;
  margin: 70px auto 70px auto;
  display: block;
  line-height: 45px;
  font-size: 14px;
  font-weight: 700;
  width: 200px;
  &:hover {
    color: #fff;
    background: black;
  }
}
.new_goods_title_hots {
  height: 40px;

  margin-top: 140px;
  margin-bottom: 140px;

  span {
    font-size: 40px;
    line-height: 28px;

    font-weight: bold;
  }
}
</style>
<style lang="scss" scoped>
.el-men u-vertical-demo:not(.el-menu--collapse) {
  width: 154px;
  min-height: 400px;
}
html {
  font-size: 16px; /* 默认字体大小 */
  @media only screen and (max-width: 1200px) {
    font-size: 12px; /* 中等屏幕 */
  }
  @media only screen and (max-width: 768px) {
    font-size: 9px; /* 中等屏幕 */
  }
  @media only screen and (max-width: 480px) {
    font-size: 6px; /* 中等屏幕 */
  }
}

#indexThree {
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 自定义按钮样式 */
    // background: rgba(255, 255, 255, 0.7);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .header_wrap {
    min-height: 100%;

    .main {
      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;
          a {
            color: #999;
            &:hover {
              color: #333;
            }
          }
          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }
          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
        .center_header {
          height: 125px;
          width: 1400px;
          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }
          .nav {
            margin-left: 50px;
            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }
            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;
              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }
            .router-link-exact-active {
              color: #f80;
            }
          }
          .head_menu_user {
            margin-top: 50px;
            img {
              margin-right: 10px;
            }
            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;
              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }
              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .login_header {
        padding-top: 30px;
        .login_img {
          display: flex;
          align-items: center;
          div:hover {
            cursor: pointer;
          }
          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }
        .login_text {
          line-height: 130px;
          font-size: 14px;
          a {
            color: #f80;
          }
        }
      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    height: 60px;
  }

  li {
    display: inline-block;
    padding: 0 42px;
    border-bottom: 2px solid transparent; /* 初始下边框颜色 */
    transition: border-bottom-color 0.3s; /* 添加过渡效果 */
    // color: white;
  }

  li:hover {
    // text-decoration: underline;
    color: #29b8b6;
    border-bottom: 2px solid #eceff7;
  }
  .footer_wrap {
    height: 200px;

    padding-top: 30px;
    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;
        img {
          width: 74px;
          margin-top: -7px;
        }
        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }
          .desc {
            font-size: 12px;
            color: #999;
          }
        }
        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }
    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;
      .jc {
        line-height: 50px;
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p {
          display: flex;
          flex-wrap: wrap;
        }
        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
  .banner {
    position: relative;
    cursor: pointer;
  }

  .banner_sub_width {
    height: 400px;

    .banner_sub {
      width: 320px;
      margin-top: 10px;
      height: 400px;
      background: #fff;
      border-radius: 4px;
    }

    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 19px;

      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .to_user_center {
      width: 220px;
      padding: 48px 0;

      span {
        background: #f80;
        color: #fff;
        line-height: 30px;
        width: 360px;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }

    .login_btn {
      margin-top: 10px;

      .left_login {
        width: 83px;
        line-height: 31px;
        font-size: 14px;
        border: 1px solid #f80;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          color: #f80;

          &:hover {
            background: #f80;
            color: #fff;
          }
        }

        &:last-child {
          background: #f80;
          color: #fff;
        }
      }
    }

    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }

    .other_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 140px;
        height: 73px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;

        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
          margin-bottom: 5px;
        }

        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    .advertising {
      display: flex;
      margin-top: 10px;

      div {
        flex: 1;

        img {
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .recommend {
      margin-top: 20px;

      .item {
        // height: 510px;
        margin-left: 20px;
        margin-bottom: 12px;
        width: 21%;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 400px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        dl {
          width: 100%;
          // border: 1px solid #eee;
          .slide-enter-active,
          .slide-leave-active {
            transition: transform 0.5s;
          }

          .slide-enter,
          .slide-leave-to {
            transform: translateX(100%);
          }
          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 100%;
          // }
          .image-transitions {
            transition: transform 0.5s ease;
          }

          // .image-transition-enter {
          //   transform: translateX(-100%);
          // }

          // .image-transition-enter-to {
          //   transform: translateX(0);
          // }

          // .image-transition-leave {
          //   transform: translateX(0);
          // }

          // .image-transition-leave-to {
          //   transform: translateX(100%);
          // }
          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 600;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            padding: 2px 10px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
          .btns {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 30px;
            /* margin: 6px auto 10px auto; */
            display: block;
            line-height: 20px;
            // font-size: 14px;
            font-weight: 700;
            padding: 2px 17px;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .itemShop {
        margin-bottom: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 800px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // &:nth-child(1),
        // &:nth-child(4n + 1) {
        //   margin-left: 0;
        // }

        dl {
          width: 100%;
          // border: 1px solid #eee;

          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 440px;
          // }

          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            box-sizing: border-box;
            border-radius: 30px;
            margin: 6px auto 10px auto;
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .itemShop1 {
        margin-bottom: 12px;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 800px;
            height: 320px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        // &:nth-child(1),
        // &:nth-child(4n + 1) {
        //   margin-left: 0;
        // }

        dl {
          width: 100%;
          // border: 1px solid #eee;

          // &:hover {
          //   cursor: pointer;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          //   width: 440px;
          // }

          img {
            width: 100%;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 17px;
              color: black;
              font-weight: 600;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            line-height: 28px;
            text-align: center;
            color: black;
            border: 1px solid black;
            box-sizing: border-box;
            border-radius: 30px;
            margin: 6px auto 10px auto;
            display: block;
            line-height: 20px;
            font-size: 14px;
            font-weight: 700;
            &:hover {
              color: #fff;
              background: black;
            }
          }
        }
      }
      .items {
        height: 470px;
        margin-left: 10px;
        margin-bottom: 12px;

        .recommend_left {
          height: 280px;
          width: 360px;

          img {
            width: 360px;
            height: 280px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:nth-child(1),
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        dl {
          width: 360px;
          // border: 1px solid #eee;

          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }

          img {
            width: 360px;
            height: 360px;
          }

          dd {
            padding: 0 5px;
          }

          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            color: black;
            line-height: 36px;
            margin-top: 25px;
          }

          .other {
            .price {
              font-size: 18px;
              color: black;
            }

            .wei {
              font-size: 14px;
              color: #666;
            }
          }

          .btn {
            width: 243px;
            line-height: 28px;
            text-align: center;
            color: #f80;
            border: 1px solid #f80;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;

            &:hover {
              color: #fff;
              background: #f80;
            }
          }
        }
      }

      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .new_goods_title_hot {
      height: 40px;

      margin-top: 140px;
      margin-bottom: 140px;

      span {
        font-size: 40px;
        line-height: 28px;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new1 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_new2 {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 40px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 40px;
      }
    }

    .new_goods_title_Image_store {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;

      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #f80;

        font-weight: bold;
        font-size: 20px;
      }
    }

    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;

      & > span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 166, 0, 0.929412)),
            to(#ff6a00)
          );
          background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }

      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;

        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;

            .title a {
              color: #ff6a00;
            }
          }

          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .summary {
            color: #767676;
            font-size: 14px;
          }

          .title a {
            font-size: 18px;
            color: #333;
          }

          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }

  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
